var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c(
        "masthead",
        { attrs: { "show-search": false } },
        [
          _c(
            "b-button",
            {
              class: _vm.$mq !== "sm" ? "mr-2 rounded" : "",
              attrs: {
                variant: "link",
                to: "/make-a-filing/company-selection",
                size: "$mq",
              },
            },
            [
              _c("feather-icon", { attrs: { type: "chevron-left" } }),
              _vm._v("\n      Back\n    "),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-row", [_c("add-company", { on: { create: _vm.create } })], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }