<template>
  <div class="mt-2">
    <masthead :show-search="false">
      <b-button
        variant="link"
        to="/make-a-filing/company-selection"
        size="$mq"
        :class="$mq !== 'sm' ? 'mr-2 rounded' : ''"
      >
        <feather-icon type="chevron-left" />
        Back
      </b-button>
    </masthead>
    <b-row>
      <add-company @create="create" />
    </b-row>
  </div>
</template>

<script>
import AddCompany from '@/components/AddCompany.vue'
import FeatherIcon from '@/components/shared/FeatherIcon'
import Masthead from '../../components/shared/Masthead'

export default {
  name: 'NewCompanySelection',
  components: { Masthead, FeatherIcon, AddCompany },
  data() {
    return {
      companyCreated: null,
    }
  },
  methods: {
    async create(companyName, entityTypeId) {
      const record = { name: companyName, entityTypeId: entityTypeId }
      this.companyCreated = await this.$store.dispatch('companies/create', {
        record,
      })

      this.$router.push({
        name: 'companySelection',
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
